import React, { useState } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import ClickButton from "./ClickButton";
import { Trans } from "react-i18next";
import { updateShopObject } from "../redux/actions/shopActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { goTo } from "../utilities/utils";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { constants } from "../redux/constants";
import SimCardCountryRadio from "./SimCardCountryRadio";

interface NeedSimModal {
  setShowModal: Function;
}

const NeedSimModal: React.FC<NeedSimModal> = (props: NeedSimModal) => {
  const shop = useSelector((state: RootState) => {
    return state.shop;
  });

  const dispatch = useDispatch();
  const [haveSim, setHaveSim] = useState(true);

  const { t } = useTranslation();

  const onRadioClickYes = () => {
    if (!haveSim) { setHaveSim(true); }
  };

  const onRadioClickNo = () => {
    if (haveSim) {
      setHaveSim(false);
    }
    dispatch(updateShopObject({ simcard_no: "" }) as any);
    dispatch(updateShopObject({ delivery_needed: true }) as any);
    dispatch(updateShopObject({ simcardOrder: true }) as any);
  };

  const onButtonClick = () => {
    if (haveSim) {
      if (step === 1) {
        props.setShowModal(false);
        return;
      }
      setStep(1);
      return;
    }
    goTo("/plans");
  };

  const [step, setStep] = useState(0);

  const getTitle = () => {
    switch (step) {
      case 0:
        return 'Already have a SIM card?';
      case 1:
        return 'Is your SIM card for ...';
    }
  }

  const onCountryChange = (country: string) => {
    dispatch(updateShopObject({ country: country }) as any);
    dispatch(updateShopObject({ currency: country === constants.USA ? constants.USA_CURRENCY : constants.CANADA_CURRENCY }) as any);
  }

  return (
    <Modal
      onClose={() => {
        props.setShowModal(false);
      }}
      noCloseButton={true}
      title={
        <Title>
          <Trans>{getTitle()}</Trans>
        </Title>
      }
      body={
        <Layout>
          {step === 0 &&
            <>
              <RadioForm>
                <RadioButton>
                  <input
                    type="radio"
                    name="sim"
                    value="Yes"
                    checked={haveSim}
                    onChange={onRadioClickYes}
                  />
                  <Trans>Yes</Trans>
                </RadioButton>
                <RadioButton>
                  <input
                    type="radio"
                    name="sim"
                    value="No"
                    checked={!haveSim}
                    onChange={onRadioClickNo}
                  />
                  <Trans>No</Trans>
                </RadioButton>
              </RadioForm>
              <TextArea>
                <Trans>{haveSim ? YES_TEXT : NO_TEXT}</Trans>
              </TextArea>
            </>
          }
          {step === 1 &&
            <div className="mb-4">
              <SimCardCountryRadio country={shop.country} onChangeValue={onCountryChange} />
            </div>
          }
          <Container>
            <ClickButton
              text={haveSim ? t("Next") : t("Shop")}
              color={"blue"}
              length={""}
              buttonLength={""}
              onClick={onButtonClick}
            ></ClickButton>
          </Container>
        </Layout>
      }
      footer={step === 0 ?
        <FooterDiv>
          <img style={{ width: "100%" }} src="/images/activation-modal.png" />
        </FooterDiv> : null
      }
    />
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
`;

const Title = styled.div`
  color: #0494ca;
  margin-top: 1rem;
`;

const RadioForm = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
`;

const RadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-right: 2rem;
`;

const TextArea = styled.div`
  text-align: center;
  font-size: 13px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 60%;
`;

const YES_TEXT = "Click ‘Next’ to continue activating your SIM card- You will be asked to enter the SIM card number and confirm your plan choice.";
const NO_TEXT = "Shop our plans and choose between a physical SIM Card to be shipped to you, or a digital SIM that you can use right away by scanning a QR Code!";

const FooterDiv = styled.div`
  height: 100%;
  border-radius: 0 0 5px 5px;
`;

const Container = styled.div`
  margin-bottom: 15px;
`;

export default NeedSimModal;