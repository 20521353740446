import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { constants } from '../redux/constants';

interface SimCardCountryRadioProps {
    country: string;
    onChangeValue: Function;
}

const SimCardCountryRadio: React.FC<SimCardCountryRadioProps> = (props: SimCardCountryRadioProps) => {
    return (
        <SwitcherContainer>
            <SwitchButton onClick={() => { props.onChangeValue("Canada"); }}>
                <SwitchImage src="/images/phonebox-sim.png" alt="" />
                <p className="text-center"><Trans>Canada</Trans></p>
                <input checked={props.country === constants.CANADA} type="radio" value="Canada" name="country" onChange={() => { props.onChangeValue("Canada"); }} />
            </SwitchButton>
            <SwitchButton onClick={() => { props.onChangeValue("USA"); }}>
                <SwitchImage src="/images/phonebox-sim-us.png" alt="" />
                <p className="text-center"><Trans>USA</Trans></p>
                <input checked={props.country === constants.USA} type="radio" value="USA" name="country" onChange={() => { props.onChangeValue("USA"); }} />
            </SwitchButton>
        </SwitcherContainer>
    );
}

const SwitcherContainer = styled.div`
    display: flex;
    gap: 30px;
`;

const SwitchButton = styled.div`
    cursor: pointer;
    user-select: none;
    width: 300px;
    height: 300px;
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    @media (max-width: 734px) {
        width: 50%;
        height: 50%;
    }
`;

const SwitchImage = styled.img`
    width: 100%;
    margin-bottom: 20px;
`;


export default SimCardCountryRadio;