import React from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

interface ClickButtonProps {
  text: string;
  color: string;
  length?: string;
  buttonLength?: string;
  onClick?: React.MouseEventHandler;
  link?: string;
  itemObject?: object;
}

const ClickButton: React.FC<ClickButtonProps> = (props: ClickButtonProps) => {
  return (
    <>
      <MyButton
        color={props.color}
        length={props.length}
        buttonLength={props.buttonLength}
        onClick={props.onClick}
      >
        <span style={{ width: "80%" }}>
          <Trans>{props.text}</Trans>
        </span>
      </MyButton>
    </>
  );
};

export default ClickButton;

const MyButton = styled.button<{
  color: string;
  length: string;
  buttonLength: string;
}>`
  border-radius: 50px;
  background-color: ${(props) =>
    props.color === "transparent" ? "transparent" : "#e13e26"};
  background-image: ${(props) =>
    props.color === "blue"
      ? "linear-gradient(to bottom, #0194e9, #35c1d0)"
      : null};
  border: ${(props) => (props.color === "transparent" ? "solid" : "none")};
  border-width: ${(props) => (props.color === "transparent" ? "1px" : "0px")};
  color: #fff;
  text-align: center;
  font-size: ${(props) => (props.length === "long" ? "14px" : "17px")};
  padding: 10px 10px;
  width: ${(props) =>
    props.buttonLength !== "" ? props.buttonLength : "200px"};
  transition: all 0.5s;
  cursor: pointer;

  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;

  :after {
    content: "→";
    position: absolute;
    opacity: 0;
    width: 20%;
    top: ${(props) => (props.length === "long" ? "1px" : "-2px")};
    right: 0;
    left: ${(props) => (props.length === "long" ? "77%" : "70%")};
    transition: 0.5s;
    font-size: ${(props) => (props.length === "long" ? "24px" : "30px")};
    text-align: center;
  }

  :hover {
    padding-right: 24px;
    padding-left: 8px;
  }

  :hover:after {
    opacity: 1;
    right: 10px;
  }
`;
